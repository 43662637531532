/**
 * @author : Herlangga Sefani <https://github.com/gaibz>
 */

'use strict';

import App from './App.svelte';

const app = new App({
    target : document.getElementById('app'),
    props : {}
});

window.app = app;

export default app;